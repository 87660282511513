/**
 * @format
 */

import React from "react";

import {
  Pressable,
  StyleSheet,
  Text,
  useWindowDimensions,
} from "react-native-web";
import { t } from "i18next";
import { theme } from "../styles/theme";
import fonts from "../styles/fonts";

const StyledButton = ({
  text,
  social,
  primary,
  onPress,
  disabled,
  style,
  textStyle,
  ...props
}) => {
  const { width } = useWindowDimensions();
  return (
    <>
      {social ? (
        <Pressable {...props} onPress={onPress} style={styles.socialButton}>
          <Text style={styles(width).socialText}>
            {t("ContinueWith", {
              social: social[0].toUpperCase() + social.slice(1),
            })}
          </Text>
        </Pressable>
      ) : (
        <Pressable
          {...props}
          disabled={disabled}
          style={[
            disabled
              ? styles(width).disabled
              : primary
              ? styles(width).primary
              : styles(width).secondary,
            style,
          ]}
          onPress={onPress}
        >
          <Text
            style={[
              primary ? styles(width).primaryText : styles(width).secondaryText,
              textStyle,
              fonts.buttonText,
            ]}
          >
            {text}
          </Text>
        </Pressable>
      )}
    </>
  );
};

const styles = (width) =>
  StyleSheet.create({
    primary: {
      width: width - 40,
      maxWidth: 520,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      height: theme.spacing.xxl,
      borderRadius: 25,
      marginBottom: theme.spacing.s,
      backgroundColor: theme.colors.primary,
    },
    secondary: {
      width: width - 40,
      maxWidth: 520,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      height: theme.spacing.xxl,
      borderWidth: 2,
      borderRadius: 25,
      borderColor: theme.colors.primary,
      marginBottom: theme.spacing.s,
    },
    disabled: {
      width: width - 40,
      maxWidth: 520,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      height: theme.spacing.xxl,
      borderWidth: 2,
      borderRadius: 25,
      borderColor: theme.colors.primaryDisabled,
      marginBottom: theme.spacing.s,
      backgroundColor: theme.colors.primaryDisabled,
    },
    primaryText: {
      fontSize: theme.sizes.l,
      color: theme.colors.white,
      fontFamily: "Raleway",
      fontWeight: 500,
      letterSpacing: 0.7,
      lineHeight: 22,
    },
    secondaryText: {
      fontSize: theme.sizes.l,
      color: theme.colors.primary,
      fontFamily: "Raleway",
      fontWeight: 500,
      lineHeight: 22,
      letterSpacing: 0.7,
    },
  });

export default StyledButton;
