import React, { useState } from "react";
import StyledButton from "../components/StyledButton";
import StyledText from "../components/StyledText";
import InputEmail from "../components/InputEmail";
import { t } from "i18next";
import { ENV } from "../env/env";
import IconEmailAlert from "../assets/icon-email-alert.svg";
import { StyleSheet, View, useWindowDimensions } from "react-native-web";
import { theme } from "../styles/theme";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const { width, height } = useWindowDimensions();
  const [emailIsValid, setEmailIsValid] = useState(true);
  const navigate = useNavigate();

  const validateEmail = (text) => {
    const reg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!reg.test(text)) {
      setEmailIsValid(false);
      return true;
    }
  };

  const onSubmit = async (email) => {
    if (validateEmail(email)) {
      return;
    }
    setEmailIsValid(true);
    try {
      const response = await fetch(
        `${ENV.ENDPOINT}/auth/sendResetPasswordCode`,
        {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        toast.error(t("defaultError"));
        return;
      }
      toast.success(t("toastSentEmail"));
      navigate(`/verificationCode/${email}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View style={styles(width, height).view}>
      <img
        src={IconEmailAlert}
        style={styles(width, height).icon}
        alt="email-alert-icon"
      />
      <StyledText
        testID="resetPasswordTitle"
        superBig
        size={theme.sizes.xxl}
        align="center"
        color={theme.colors.primary}
      >
        {t("resetPasswordTitle")}
      </StyledText>
      <StyledText mainText size={theme.sizes.l} align="center">
        {t("resetPasswordSubtitle")}
        <StyledText screenTitleBold size={theme.sizes.l}>
          {t("resetPasswordBold")}
        </StyledText>
      </StyledText>
      <View style={styles(width, height).form}>
        <InputEmail
          testID="emailInput"
          setEmail={setEmail}
          emailIsValid={emailIsValid}
          setEmailIsValid={setEmailIsValid}
        />
      </View>
      <StyledButton
        testID="resetPasswordButton"
        primary
        text={t("resetPasswordButton")}
        disabled={email === "" || !emailIsValid}
        onPress={() => {
          onSubmit(email);
        }}
      />
    </View>
  );
};

const styles = (width, height) =>
  StyleSheet.create({
    view: {
      flex: 1,
      backgroundColor: theme.colors.white,
      alignItems: "center",
    },
    form: {
      marginVertical: theme.spacing.m,
    },
    icon: {
      marginTop: theme.spacing.m,
      marginBottom: theme.spacing.xxs,
      alignSelf: "center",
    },
  });

export default ForgotPasswordPage;
