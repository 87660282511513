import i18n from "i18next";
import translationES from "./es/translationES.json";
import translationEN from "./en/translationEN.json";
import translationCAT from "./cat/translationCAT.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
  ca: {
    translation: translationCAT,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
  });

export default i18n;
