/* eslint-disable react-hooks/exhaustive-deps */
import i18next, { t } from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import StyledText from "../components/StyledText";
import VisaIcon from "../assets/visa.svg";
import MastercardIcon from "../assets/mastercard.svg";
import StripeIcon from "../assets/stripe.svg";
import ScanOkayIcon from "../assets/scan-okay.svg";
import ScanErrorIcon from "../assets/scan-error.svg";
import { theme } from "../styles/theme";
import { ENV } from "../env/env";
import { Pressable, StyleSheet, View } from "react-native-web";
import { UserContext } from "../application/UserContext";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import StyledButton from "../components/StyledButton";

const PaymentMethodPage = () => {
  const stripe = useStripe();
  const [paymentMethod, setPaymentMethod] = useState();
  const [editing, setEditing] = useState();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const getUser = useCallback(async () => {
    const response = await fetch(`${ENV.ENDPOINT}/users/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
    const data = await response.json();
    if (!data.success) {
      toast.error(t(data.code));
      return;
    }
    setUser({ ...user, ...data.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultPaymentMethod = useCallback(async () => {
    const response = await fetch(`${ENV.ENDPOINT}/paymentMethod/me/default`, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": i18next.language.slice(0, 2),
      },
    });
    const data = await response.json();
    setPaymentMethod(data.data);
  }, []);

  const getPaymentMethodEvents = useCallback(async () => {
    const response = await fetch(
      `${ENV.ENDPOINT}/paymentMethodEvents?dir=DESC&order=createdAt&limit=5&select=id,type,createdAt`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          "Content-Type": "application/json",
          "Accept-Language": i18next.language.slice(0, 2),
        },
      }
    );
    const data = await response.json();
    switch (data.data[0].type) {
      case "payment_method_expires_very_soon":
        toast.error(t("paymentMethodExpiringSoon"));
        break;
      case "payment_method_expired":
        toast.error(t("paymentMethodExpired"));
        break;
      case "payment_method_blocked":
        toast.error(t("paymentMethodBlocked"));
        break;
      case "payment_method_intent_created":
        getPaymentMethodEvents();
        break;
      case "payment_method_updated":
        setUser({ ...user, paymentStatus: "configured" });
        navigate("/userCode");
        setTimeout(() => {
          toast.success(t("paymentMethodSaved"));
        }, 500);
        break;
      default:
        toast.error(t("paymentMethodFailed"));
    }
    setLoading(false);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.error(submitError.message);
      return;
    }

    const response = await fetch(`${ENV.ENDPOINT}/paymentMethod/intent`, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": i18next.language.slice(0, 2),
      },
    });
    const data = await response.json();
    const clientSecret = data.data.id;

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://webapp.youbumerang.com",
        payment_method_data: {
          billing_details: { email: user.email, name: user.name },
        },
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: "if_required",
    });

    //Feedback
    if (error) {
      toast.error(error.message);
      setLoading(false);
    } else {
      getPaymentMethodEvents();
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (!user.accessToken) {
      navigate("/", { replace: true });
    }
    getDefaultPaymentMethod();
  }, []);

  return (
    <View style={styles.container}>
      <StyledText screenTitleBold align={"center"}>
        {user.paymentStatus === "configured"
          ? t("yourPaymentMethod")
          : t("AddPaymentMethodWarning")}
      </StyledText>
      <StyledText mainText align={"center"}>
        {t("PaymentWarning")}
      </StyledText>
      {!paymentMethod || editing ? (
        <>
          <PaymentElement
            options={{
              wallets: { applePay: "auto", googlePay: "auto" },
            }}
          />
          <StyledButton
            style={{ alignSelf: "center" }}
            primary
            text={t("paymentMethodModalAddCard")}
            onPress={async (e) => {
              setLoading(true);
              handleSubmit(e);
            }}
            disabled={loading}
          />
        </>
      ) : (
        <>
          <View style={styles.methodView}>
            <View style={styles.row}>
              {paymentMethod.type === "card" &&
                paymentMethod.card.brand === "visa" && (
                  <img src={VisaIcon} alt="visa-icon" width={30} height={30} />
                )}
              {paymentMethod.type === "card" &&
                paymentMethod.card.brand === "mastercard" && (
                  <img
                    src={MastercardIcon}
                    alt="mastercard-icon"
                    width={30}
                    height={30}
                  />
                )}
              {paymentMethod.type === "card" &&
                paymentMethod.card.brand !== "mastercard" &&
                paymentMethod.type === "card" &&
                paymentMethod.card.brand !== "visa" && (
                  <img
                    src={StripeIcon}
                    alt="stripe-icon"
                    width={30}
                    height={30}
                  />
                )}
              <StyledText mainText>{`...${
                paymentMethod.type === "card"
                  ? paymentMethod.card.last4
                  : paymentMethod.bank.last4
              }`}</StyledText>
            </View>
            {user.paymentStatus === "configured" ? (
              <img src={ScanOkayIcon} width={20} alt="okay-icon" />
            ) : (
              <img src={ScanErrorIcon} width={20} alt="okay-icon" />
            )}
          </View>
          <View>
            <Pressable
              testID="updatePaymentMethodButton"
              onPress={() => setEditing(true)}
            >
              <StyledText
                mainTextLink
                color={
                  user.paymentStatus !== "configured"
                    ? theme.colors.error
                    : theme.colors.primary
                }
              >
                {t("updatePaymentMethod")}
              </StyledText>
            </Pressable>
          </View>
        </>
      )}
    </View>
  );
};

export default PaymentMethodPage;

const styles = StyleSheet.create({
  container: {
    alignItems: "space-around",
    justifyContent: "space-around",
    padding: theme.spacing.s,
    gap: theme.spacing.s,
  },
  center: { alignItems: "center" },
  methodView: {
    width: "70%",
    flexDirection: "row",
    marginTop: theme.spacing.s,
    marginBottom: theme.spacing.xs,
    padding: theme.spacing.s,
    height: 60,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: theme.colors.primaryDisabled,
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    width: 90,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
