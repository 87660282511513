import React, { useContext, useEffect, useCallback, useState } from "react";
import { t } from "i18next";
import CopyIcon from "../assets/copy-clipboard.svg";
import AppStoreIcon from "../assets/app-store.svg";
import PlayStoreIcon from "../assets/play-store.svg";
import ConfirmationIcon from "../assets/Icono_Confirmación_Webapp_normon.svg";
import StyledText from "../components/StyledText";
import {
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
  Clipboard,
} from "react-native-web";
import { theme } from "../styles/theme";
import { QRCodeSVG } from "qrcode.react";
import { UserContext } from "../application/UserContext";
import { ENV } from "../env/env";
import { toast } from "react-toastify";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import StyledButton from "../components/StyledButton";
import Separator from "../components/Separator";
import InputText from "../components/InputText";

const UserCodePage = () => {
  const { width } = useWindowDimensions();
  const { user, setUser } = useContext(UserContext);
  const [employeeId, setEmployeeId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedEmployeeId, setEditedEmployeeId] = useState("");
  const [employeeIdIsValid, setEmployeeIdIsValid] = useState(true);

  useEffect(() => {
    const validateEmployeeId = (id) => {
      // Verifica si el id tiene solo números y exactamente 6 dígitos
      const employeeIdIsValid = /^\d{6}$/.test(id);
      setEmployeeIdIsValid(employeeIdIsValid);
    };

    if (user?.idForThirdParty?.includes("ensa")) {
      validateEmployeeId(editedEmployeeId);
    }
  }, [editedEmployeeId]);

  const navigate = useNavigate();
  const getUser = useCallback(async () => {
    const token = await AsyncStorage.getItem("accessToken");
    const response = await fetch(
      `${ENV.ENDPOINT}/users/me?select=idForThirdParty,id,name,email,type,paymentStatus,username`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken ?? token}`,
        },
      }
    );
    const data = await response.json();
    if (!data.success) {
      toast.error(t(data.code));
      return;
    }
    if (data.data.paymentStatus !== "configured") {
      navigate("/paymentMethod");
    }
    if (token) {
      setUser({ ...data.data, accessToken: token });
      if (data.data.idForThirdParty) {
        setEmployeeId(
          data.data.idForThirdParty.substring(
            data.data.idForThirdParty.indexOf("-") + 1
          )
        );
      }
    } else {
      setUser({ ...user, ...data.data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const editUser = useCallback(async () => {
    try {
      console.log("ID", editedEmployeeId);
      const token = await AsyncStorage.getItem("accessToken");
      await fetch(`${ENV.ENDPOINT}/users/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken ?? token}`,
        },
        method: "PATCH",
        body: JSON.stringify({
          idForThirdParty: "ensa-" + editedEmployeeId,
        }),
      });
      toast.success(t("EmployeeIdUpdated"));

      getUser();
    } catch (error) {
      toast.error(error.message);
      return;
    }
    /* if (token) {
        setUser({ ...data.data, accessToken: token });
        if (data.data.idForThirdParty) {
          setEmployeeId(
            data.data.idForThirdParty.substring(
              data.data.idForThirdParty.indexOf("-") + 1
              )
              );
              }
              } else {
                setUser({ ...user, ...data.data });
            } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedEmployeeId]);

  const syncAndClose = async () => {
    const redirection = await AsyncStorage.getItem("redirection");
    if (redirection && redirection !== "undefined") {
      window.location.href = redirection.includes("http")
        ? `${redirection}?userId=${user.username}`
        : `https://${redirection}?userId=${user.username}`;
    }
    window.parent?.postMessage(
      JSON.stringify({ event: "exit", userId: user.username }),
      "*"
    );
    window.AndroidInterface?.receiveMessage(
      JSON.stringify({ event: "exit", userId: user.username })
    );
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ event: "exit", userId: user.username }),
      "*"
    );
    window.webkit?.messageHandlers?.bumerangListener?.postMessage(
      JSON.stringify({ event: "exit", userId: user.username }),
      "*"
    );
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    window.parent?.postMessage(
      JSON.stringify({ event: "userId", userId: user.username }),
      "*"
    );
    window.AndroidInterface?.receiveMessage(
      JSON.stringify({ event: "userId", userId: user.username })
    );
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ event: "userId", userId: user.username }),
      "*"
    );
    window.webkit?.messageHandlers?.bumerangListener?.postMessage(
      JSON.stringify({ event: "userId", userId: user.username }),
      "*"
    );
  }, [user]);

  return !user.idForThirdParty ? (
    <View style={styles(width).container}>
      <View style={styles().topContainer}>
        <StyledText
          align="center"
          color={theme.colors.primary}
          mainText
          style={styles().subtitle}
        >
          {t("showYourQrCode")}
        </StyledText>
        <View testID="qrView" style={styles(width).qrViewBox}>
          <View style={styles().qrBoxHeader}>
            <View style={styles().nameTitleStyle}>
              {user && (
                <StyledText subtitle size={theme.sizes.m} align="center">
                  {user.name}
                </StyledText>
              )}
            </View>
          </View>
          {user && <QRCodeSVG value={user.username} />}
        </View>
        <Pressable
          onPress={() => {
            Clipboard.setString(
              user.idForThirdParty ? employeeId : user.username
            );
            toast.success(t("copiedToClipboard"));
          }}
          style={styles().copyTextBox}
        >
          {user && (
            <StyledText
              style={{ marginRight: theme.spacing.xs }}
              subtitle
              size={theme.sizes.m}
            >
              {user.idForThirdParty ? employeeId : user.username}
            </StyledText>
          )}
          <img style={{ width: "1rem" }} src={CopyIcon} alt="copy-icon" />
        </Pressable>
        <StyledButton
          onPress={syncAndClose}
          style={{ marginTop: theme.spacing.s }}
          primary
          text={t("SyncAndClose")}
        ></StyledButton>
        <Pressable
          onPress={() => {
            navigate("/paymentMethod");
          }}
        >
          <StyledText
            style={{ marginTop: theme.spacing.l }}
            mainTextLink
            align="center"
          >
            {t("updatePaymentMethod")}
          </StyledText>
        </Pressable>
      </View>
      <Pressable
        onPress={async () => {
          const redirection = await AsyncStorage.getItem("redirection");
          if (redirection && redirection !== "undefined") {
            window.location.href = redirection.includes("http")
              ? `${redirection}`
              : `https://${redirection}`;
          }
          window.parent?.postMessage(JSON.stringify({ event: "logout" }), "*");
          window.AndroidInterface?.receiveMessage(
            JSON.stringify({ event: "logout" })
          );

          window.ReactNativeWebView?.postMessage(
            JSON.stringify({ event: "logout" }),
            "*"
          );
          window.webkit?.messageHandlers?.bumerangListener?.postMessage(
            JSON.stringify({ event: "logout" }),
            "*"
          );
          navigate("/", { replace: true });
          setUser({});
          AsyncStorage.multiRemove([
            "accessToken",
            "email",
            "name",
            "tab",
            "redirection",
          ]);
        }}
      >
        <StyledText mainTextLink align="flex-end">
          {t("LogOut")}
        </StyledText>
      </Pressable>
    </View>
  ) : (
    <View style={styles(width).container}>
      <View style={styles().topContainer}>
        <img
          style={{ width: 180, height: 180 }}
          src={ConfirmationIcon}
          alt="copy-icon"
        />
        <StyledText screenTitleRegular align="center">
          {t("registerComplete1")}
        </StyledText>
        <StyledText screenTitleRegular align="center">
          {t("registerComplete2")}
        </StyledText>
        <StyledText
          align="center"
          color={theme.colors.primary}
          mainText
          style={styles().subtitle}
        >
          {t("showCode1")}
        </StyledText>
        <StyledText align="center" color={theme.colors.primary} mainText>
          {t("showCode2")}
        </StyledText>
        {user && (
          <QRCodeSVG
            style={{ marginTop: theme.spacing.m }}
            value={user.username}
          />
        )}
        <View
          style={{
            marginTop: theme.spacing.s,
            gap: theme.spacing.s,
          }}
        >
          <Pressable
            onPress={() => {
              Clipboard.setString(user.employeeId);
              toast.success(t("copiedToClipboard"));
            }}
            style={styles().copyTextBox}
          >
            <View style={{ flexDirection: "row" }}>
              <StyledText
                style={{
                  marginRight: theme.spacing.xs,
                }}
                subtitle
                size={30}
              >
                {employeeId}
              </StyledText>
              <img style={{ width: "2rem" }} src={CopyIcon} alt="copy-icon" />
            </View>
          </Pressable>
          {user.idForThirdParty.includes("ensa") && (
            <Pressable
              onPress={() => {
                setIsEditing(true);
              }}
              style={styles().copyTextBox}
            >
              {isEditing ? (
                <View style={{ alignItems: "center", gap: theme.spacing.s }}>
                  <InputText
                    setText={setEditedEmployeeId}
                    style={{ width: "100%" }}
                    placeholder={employeeId}
                  />
                  {!employeeIdIsValid && (
                    <StyledText color={theme.colors.error}>
                      {t("EmployeeIdValidation")}
                    </StyledText>
                  )}
                  <StyledButton
                    disabled={!employeeIdIsValid}
                    onPress={() => {
                      editUser();
                    }}
                    style={{ width: "100%" }}
                    text={t("Save")}
                    secondary
                  />
                </View>
              ) : (
                <StyledText mainTextLink size={theme.sizes.xl}>
                  {t("Edit")}
                </StyledText>
              )}
            </Pressable>
          )}
        </View>

        {/*   <Pressable
          onPress={() => {
            navigate("/paymentMethod");
          }}
        >
          <StyledText
            style={{ marginTop: theme.spacing.l }}
            mainTextLink
            align="center"
          >
            {t("updatePaymentMethod")}
          </StyledText>
        </Pressable> */}
      </View>
      <StyledButton
        onPress={async () => {
          const redirection = await AsyncStorage.getItem("redirection");
          if (redirection && redirection !== "undefined") {
            window.location.href = redirection.includes("http")
              ? `${redirection}`
              : `https://${redirection}`;
          }
          window.parent?.postMessage(JSON.stringify({ event: "logout" }), "*");
          window.AndroidInterface?.receiveMessage(
            JSON.stringify({ event: "logout" })
          );

          window.ReactNativeWebView?.postMessage(
            JSON.stringify({ event: "logout" }),
            "*"
          );
          window.webkit?.messageHandlers?.bumerangListener?.postMessage(
            JSON.stringify({ event: "logout" }),
            "*"
          );
          navigate("/", { replace: true });
          setUser({});
          AsyncStorage.multiRemove([
            "accessToken",
            "email",
            "name",
            "tab",
            "redirection",
          ]);
        }}
        style={{ marginTop: theme.spacing.s }}
        primary
        text={t("LogOut")}
      ></StyledButton>
      <Separator isOption margin={theme.spacing.m} width={width * 0.6} />
      <View style={styles(width).storeLinksContainer}>
        <StyledText align={"center"} mainText>
          {t("downloadBumerang1")}
        </StyledText>
        <StyledText align={"center"} mainText>
          {t("downloadBumerang2")}
        </StyledText>
        <View style={styles(width).storeLinksView}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <img
              style={{ width: 180, height: 100 }}
              src={PlayStoreIcon}
              alt="copy-icon"
            />
            <img
              style={{ width: 180, height: 100 }}
              src={AppStoreIcon}
              alt="copy-icon"
            />
          </View>
          <QRCodeSVG value={"http://onelink.to/yqed5n"} />
        </View>
      </View>
    </View>
  );
};

const styles = (width, height) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      paddingHorizontal: theme.spacing.m,
      paddingBottom: theme.spacing.m,
    },
    topContainer: {
      alignItems: "center",
    },
    subtitle: {
      marginTop: theme.spacing.m,
    },
    shadowBox: {
      marginVertical: theme.spacing.m,
    },
    qrViewBox: {
      height: 220,
      width: width * 0.67,
      paddingBottom: theme.spacing.m,
      borderRadius: 20,
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    qrBoxHeader: {
      width: "100%",
      flexWrap: "wrap",
      paddingHorizontal: theme.spacing.xs,
      alignContent: "center",
      alignItems: "center",
    },
    nameTitleStyle: {
      maxWidth: "70%",
    },
    chatBubbleIcon: {
      position: "absolute",
      left: 15,
    },
    qrCodeBox: {
      backgroundColor: theme.colors.white,
      width: width * 0.67,
      borderRadius: 5,
      height: 45,
      alignItems: "center",
      justifyContent: "space-evenly",
      flexDirection: "row",
      marginBottom: theme.spacing.s,
    },
    icon: {
      marginRight: theme.spacing.xs,
    },
    copyTextBox: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    storeLinksContainer: {
      alignItems: "center",
    },
    storeLinksView: {
      alignItems: "center",
      justifyContent: "space-around",
    },
    logout: { marginTop: "auto" },
  });

export default UserCodePage;
