import React from "react";
import {
  StyleSheet,
  TextInput,
  View,
  useWindowDimensions,
} from "react-native-web";
import StyledText from "./StyledText";
import { t } from "i18next";
import { theme } from "../styles/theme";

const InputEmail = ({
  setEmail,
  emailIsValid,
  setEmailIsValid,
  placeholder,
  ...props
}) => {
  const { width } = useWindowDimensions();
  return (
    <View
      style={[
        styles(width).form,
        {
          borderBottomColor: !emailIsValid
            ? theme.colors.error
            : theme.colors.primaryDisabled,
        },
      ]}
    >
      <TextInput
        testID="emailInput"
        {...props}
        style={styles(width).input}
        onChangeText={(event) => {
          setEmail(event.valueOf());
          setEmailIsValid(true);
        }}
        keyboardType="email-address"
        textContentType="emailAddress"
        autoComplete="email"
        autoCapitalize="none"
        enablesReturnKeyAutomatically={true}
        placeholder={placeholder ?? "Email"}
        placeholderTextColor={"darkgray"}
      />
      {!emailIsValid && (
        <StyledText
          testID="emailErrorMessage"
          mainText
          color={theme.colors.error}
        >
          {t("InputEmailWrong")}
        </StyledText>
      )}
    </View>
  );
};

const styles = (width) =>
  StyleSheet.create({
    form: {
      width: width - 40,
      maxWidth: 500,
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.primaryDisabled,
      marginBottom: theme.spacing.m,
      fontSize: 50,
    },
    input: {
      width: "100%",
      padding: theme.spacing.s,
      fontSize: theme.sizes.l,
      color: theme.colors.black,
    },
  });

export default InputEmail;
