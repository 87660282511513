import { View } from "react-native-web";
import emptyState from "../assets/IconNotFound.svg";
import StyledText from "../components/StyledText";
import { t } from "i18next";
const NotFoundPage = () => {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <img style={{ width: "5rem" }} src={emptyState} alt="not-found" />
      <StyledText
        style={{
          textAlign: "center",
          paddingTop: 50,
          fontSize: 25,
          fontFamily: "Raleway",
          color: "#FFF243",
        }}
      >
        {t("PageNotFound")}
      </StyledText>
    </View>
  );
};

export default NotFoundPage;
