import { StyleSheet, View } from "react-native-web";
import { theme } from "../styles/theme";

const Card = ({ children, style }) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: theme.spacing.s,
    gap: theme.spacing.xs,
    borderRadius: 10,
    zIndex: 1,
    backgroundColor: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 5px 6px -2px",
  },
});
export default Card;
