import React from "react";
import { StyleSheet, TextInput, View } from "react-native";

import { t } from "i18next";
import { theme } from "../styles/theme";
import { useWindowDimensions } from "react-native-web";

const InputText = ({ setText, placeholder, editable, style, ...props }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={[styles(width).form, style]}>
      <TextInput
        {...props}
        testID="textInput"
        editable={editable}
        style={styles(width).input}
        onChangeText={(event) => setText(event.valueOf())}
        autoComplete="off"
        textContentType="name"
        autoCorrect={false}
        autoCapitalize="none"
        enablesReturnKeyAutomatically={true}
        multiline={false}
        placeholder={placeholder ?? t("NameAndSurname")}
        placeholderTextColor={"darkgray"}
      />
    </View>
  );
};

const styles = (width) =>
  StyleSheet.create({
    form: {
      width: width - 40,
      maxWidth: 500,
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.primaryDisabled,
      marginBottom: theme.spacing.m,
      fontSize: 50,
    },
    eye: {
      marginLeft: "auto",
      marginRight: 20,
    },
    input: {
      flex: 1,
      fontSize: theme.sizes.l,
      color: theme.colors.black,
      padding: theme.spacing.s,
    },
    label: {
      fontSize: theme.sizes.m,
      color: theme.colors.black,
      marginLeft: theme.spacing.s,
      marginBottom: -7,
    },
  });

export default InputText;
