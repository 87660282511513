import MapBowlIcon from "../assets/map-bowl.svg";
import MapCupIcon from "../assets/map-cup.svg";
import MapCupBowlIcon from "../assets/map-bowlcup.svg";

export const getMapIcon = (item) => {
  if (
    item.productGroupTypeList.includes("Bowl") &&
    item.productGroupTypeList.includes("Cup")
  ) {
    return MapCupBowlIcon;
  }
  if (
    item.productGroupTypeList.includes("Bowl") &&
    !item.productGroupTypeList.includes("Cup")
  ) {
    return MapBowlIcon;
  }
  if (
    item.productGroupTypeList.includes("Cup") &&
    !item.productGroupTypeList.includes("Bowl")
  ) {
    return MapCupIcon;
  }
};
