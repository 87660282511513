import {
  CheckBox,
  Linking,
  Modal,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native-web";
import { theme } from "../styles/theme";
import StyledText from "../components/StyledText";
import { useContext, useEffect, useState } from "react";
import InputEmail from "../components/InputEmail";
import InputText from "../components/InputText";
import InputPassword from "../components/InputPassword";
import StyledButton from "../components/StyledButton";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../application/UserContext";
import { toast } from "react-toastify";
import { ENV } from "../env/env";
import i18next, { t } from "i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getPartner } from "../utils/getPartner";
import NotFoundPage from "./NotFoundPage";
import queryString from "query-string";
import EmailIcon from "../assets/Email_icon.svg";
import PaymentMethodIcon from "../assets/Método de pago_icon.svg";
import CodeIcon from "../assets/Número de empleado_icon.svg";

const RegisterPartnerPage = () => {
  const [loginTab, setLoginTab] = useState(false);
  const [email, setEmail] = useState("");
  const [partner, setPartner] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [password, setPassword] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [newsletterCheck, setNewsletterCheck] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [employeeIdIsValid, setEmployeeIdIsValid] = useState(true);
  const [text, setText] = useState("");
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [modalVisible, setModalVisible] = useState(
    height > width ? false : true
  );

  useEffect(() => {
    const validateEmployeeId = (id) => {
      // Verifica si el id tiene solo números y exactamente 6 dígitos
      const employeeIdIsValid = /^\d{6}$/.test(id);
      setEmployeeIdIsValid(employeeIdIsValid);
    };

    if (window.location.href.includes("ensa")) {
      validateEmployeeId(employeeId);
    }
  }, [employeeId]);

  const handleSetEmployeeId = (id) => {
    if (!window.location.href.includes("ensa") || /^\d{6}$/.test(id)) {
      setEmployeeId(id);
    } else {
      setEmployeeIdIsValid(false);
    }
  };

  const validateEmail = (text) => {
    const reg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!reg.test(text)) {
      setEmailIsValid(false);
      return true;
    }
  };

  useEffect(() => {
    const loadParams = async () => {
      const name = await AsyncStorage.getItem("name");
      const email = await AsyncStorage.getItem("email");
      partner !== "undefined" &&
        setPartner(
          getPartner(
            window.location.href.substring(
              window.location.href.lastIndexOf("/") + 1
            )
          )
        );
      email !== "undefined" && setEmail(email);
      name !== "undefined" && setText(name);
    };
    loadParams();
  }, [partner]);

  useEffect(() => {
    const checkSession = async () => {
      const token = await AsyncStorage.getItem("accessToken");
      if (token && token !== "undefined") {
        navigate("/userCode", { replace: true });
      }
    };
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = async (body) => {
    if (validateEmail(body.email)) {
      return;
    }
    setEmailIsValid(true);
    try {
      const response = await fetch(`${ENV.ENDPOINT}/auth/login`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(t(data.code));
        return;
      }
      await AsyncStorage.setItem("accessToken", data.data.accessToken);
      const currentUserResponse = await fetch(`${ENV.ENDPOINT}/users/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
        },
      });
      const currentUser = await currentUserResponse.json();
      if (!currentUser.success) {
        toast.error(t(currentUser.code));
        return;
      }
      const { successUrl, allowAccess } = queryString.parse(
        window.location.search
      );
      setUser({ ...currentUser.data, accessToken: data.data.accessToken });
      successUrl &&
        allowAccess &&
        setUser({
          ...currentUser.data,
          successUrl: successUrl,
          allowAccess: allowAccess,
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.allowAccess) {
      navigate("/linkPermission", { replace: true });
    }
    if (user?.accessToken) {
      navigate("/userCode", { replace: true });
    }
  }, [navigate, user?.accessToken, user?.allowAccess]);

  const onRegister = async (body) => {
    if (validateEmail(body.email)) {
      return;
    }
    setEmailIsValid(true);
    try {
      const response = await fetch(`${ENV.ENDPOINT}/auth/register`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(t(data.code));
        return;
      }
      await AsyncStorage.setItem("accessToken", data.data.accessToken);
      const currentUserResponse = await fetch(`${ENV.ENDPOINT}/users/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
        },
      });
      const currentUser = await currentUserResponse.json();
      if (!currentUser.success) {
        toast.error(t(currentUser.code));
        return;
      }
      setUser({ ...currentUser.data, accessToken: data.data.accessToken });
    } catch (error) {
      console.error(error);
    }
  };

  const formIncomplete =
    email === "" ||
    text === "" ||
    password === "" ||
    employeeId === "" ||
    employeeIdIsValid === false ||
    termsAndConditions === false;

  return partner ? (
    <View
      style={{
        backgroundColor: theme.colors.white,
        width: width,
      }}
    >
      {partner.logo && (
        <View style={{ alignSelf: "center", paddingVertical: theme.spacing.s }}>
          <img src={partner.logo} width={100} alt={`${partner.slug}-logo`} />
        </View>
      )}
      <View style={styles(width).tabs}>
        <Pressable
          testID="loginTab"
          onPress={() => {
            setLoginTab(true);
          }}
          style={styles(width).tab}
        >
          <StyledText
            style={!loginTab && styles().opacity}
            subtitle
            color={theme.colors.primary}
            mainTextLink={loginTab}
            size={theme.sizes.l}
          >
            {t("LogIn")}
          </StyledText>
        </Pressable>
        <Pressable
          testID="registerTab"
          onPress={() => {
            setLoginTab(false);
          }}
          style={styles(width).tab}
        >
          <StyledText
            style={loginTab && styles().opacity}
            subtitle
            color={theme.colors.primary}
            mainTextLink={!loginTab}
            size={theme.sizes.l}
          >
            {t("Register")}
          </StyledText>
        </Pressable>
      </View>

      {loginTab ? (
        <View style={styles(width, height).formView}>
          <InputEmail
            defaultValue={email}
            setEmail={setEmail}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
          />
          <View style={styles(width).formViewPassword}>
            <InputPassword setPassword={setPassword} />
          </View>
          <StyledButton
            testID="loginButton"
            style={{ marginVertical: theme.spacing.s }}
            text={t("LogIn")}
            disabled={email === "" || password === ""}
            onPress={() => onLogin({ email, password })}
            primary
          />
          <Link to={"forgotPassword"}>
            <StyledText style={{ marginBottom: theme.spacing.m }} mainTextLink>
              {t("ForgottenPassword")}
            </StyledText>
          </Link>
        </View>
      ) : (
        <View style={styles(width, height).formView}>
          <InputEmail
            defaultValue={email}
            setEmailIsValid={setEmailIsValid}
            setEmail={setEmail}
            emailIsValid={emailIsValid}
          />
          <InputText defaultValue={text} setText={setText} />
          <InputText
            placeholder={t("employeeId")}
            defaultValue={employeeId}
            setText={setEmployeeId}
            maxLength={window.location.href.includes("ensa") && 6}
            keyboardType={window.location.href.includes("ensa") && "numeric"}
          />
          {!employeeIdIsValid && (
            <StyledText color={theme.colors.error}>
              {t("EmployeeIdValidation")}
            </StyledText>
          )}
          <View style={styles(width).formViewPassword}>
            <InputPassword setPassword={setPassword} />
          </View>
          <View>
            <View style={styles().newsLetterView}>
              <CheckBox
                testID="newsletterCheck"
                boxType="square"
                tintColor={
                  newsletterCheck
                    ? theme.colors.primary
                    : theme.colors.primaryDisabled
                }
                value={newsletterCheck}
                onValueChange={() => setNewsletterCheck(!newsletterCheck)}
              />
              <StyledText style={styles().checkText} mainText>
                {t("Communications")}
              </StyledText>
            </View>
            <View style={styles().newsLetterView}>
              <CheckBox
                testID="termsCheck"
                tintColor={
                  newsletterCheck
                    ? theme.colors.primary
                    : theme.colors.primaryDisabled
                }
                value={termsAndConditions}
                onValueChange={() => setTermsAndConditions(!termsAndConditions)}
              />
              <Pressable
                onPress={() =>
                  Linking.openURL(
                    "https://bumerang.webflow.io/politicas/politica-de-privacidad-de-datos"
                  )
                }
              >
                <StyledText style={styles().checkText} mainText>
                  {t("AcceptNewsLetters")}
                  <StyledText mainTextLink size={theme.sizes.s}>
                    {t("AcceptNewsLettersLink")}
                  </StyledText>
                </StyledText>
              </Pressable>
            </View>
          </View>
          <StyledButton
            testID="registerButton"
            style={{ marginVertical: theme.spacing.s }}
            text={t("ContinueWithPaymentMethod")}
            onPress={() =>
              onRegister({
                email,
                password,
                name: text,
                idForThirdParty: employeeId,
                partnerId: partner.id,
                platform: "web",
                language: i18next.language.slice(0, 2),
              })
            }
            disabled={formIncomplete}
            primary
          />
        </View>
      )}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <View style={styles().centeredView}>
          <View style={styles(width, height).modalView}>
            <View style={styles().listContainer}>
              <StyledText screenTitleRegular>{t("createAccount")}</StyledText>
              <View style={styles().modalSteps}>
                <View style={styles().modalRow}>
                  <img src={EmailIcon} width={35} alt="Email icon" />
                  <StyledText style={{ marginLeft: theme.spacing.s }} mainText>
                    {t("registerEmail")}
                  </StyledText>
                </View>
                <View style={styles().modalRow}>
                  <img src={CodeIcon} width={35} alt="Code icon" />
                  <StyledText style={{ marginLeft: theme.spacing.s }} mainText>
                    {t("enterEmployeeId")}
                  </StyledText>
                </View>
                <View style={styles().modalRow}>
                  <img
                    src={PaymentMethodIcon}
                    width={35}
                    alt="Payment method icon"
                  />
                  <StyledText style={{ marginLeft: theme.spacing.s }} mainText>
                    {t("addPaymentMethod")}
                  </StyledText>
                </View>
              </View>
            </View>
            <StyledButton
              onPress={() => setModalVisible(!modalVisible)}
              primary
              style={{
                width: "90%",
                marginBottom: 0,
                marginTop: "auto",
              }}
              text={t("createAccountButton")}
            />
          </View>
        </View>
      </Modal>
    </View>
  ) : (
    <NotFoundPage />
  );
};

const styles = (width, height) =>
  StyleSheet.create({
    opacity: { opacity: 0.7 },
    checkText: { marginLeft: theme.spacing.xs },
    tabs: {
      height: 70,
      shadowColor: "black",
      shadowOpacity: 0.26,
      shadowOffset: { width: 0, height: 2 },
      elevation: 3,
      marginBottom: 2,
      maxWidth: 800,
      width: width,
      alignSelf: "center",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      backgroundColor: theme.colors.white,
    },
    tab: {
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    formView: {
      height: height,
      width: width,
      alignItems: "center",
      padding: theme.spacing.s,
      backgroundColor: theme.colors.white,
    },
    formViewPassword: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing.xs,
    },
    newsLetterView: {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
      marginBottom: theme.spacing.xs,
      paddingRight: theme.spacing.m,
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
    modalView: {
      flex: 0.36,
      width: height > width ? "80%" : "30%",
      backgroundColor: "white",
      borderRadius: 20,
      padding: 50,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      alignItems: "center",
    },
    modalRow: {
      alignItems: "center",
      flexDirection: "row",
    },
    modalSteps: {
      flex: 0.9,
      justifyContent: "space-evenly",
    },
    listContainer: {
      flex: 1,
    },
  });

export default RegisterPartnerPage;
