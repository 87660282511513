import { View } from "react-native-web";
import Card from "./Card";
import StyledText from "./StyledText";
import { theme } from "../styles/theme";
import { getContainerInfo } from "../utils/getContainerInfo";

const ContainerItem = ({ containerName, productTypeId }) => {
  return (
    <Card style={{ padding: theme.spacing.xs }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing.xs,
        }}
      >
        <img
          src={getContainerInfo(productTypeId).icon}
          alt="bowl returning"
        ></img>
        <StyledText>{`${
          getContainerInfo(productTypeId).name
        } - ${containerName}`}</StyledText>
      </View>
    </Card>
  );
};

export default ContainerItem;
