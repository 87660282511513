import React, { useState } from "react";
import StyledButton from "../components/StyledButton";

import StyledText from "../components/StyledText";
import { t } from "i18next";
import InputPassword from "../components/InputPassword";

import IconNewPassword from "../assets/icon-new-password.svg";
import { StyleSheet, View, useWindowDimensions } from "react-native-web";
import { theme } from "../styles/theme";
import { ENV } from "../env/env";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const NewPasswordPage = ({ route }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { width, height } = useWindowDimensions();
  const isDisabled =
    password === "" || confirmPassword === "" || password !== confirmPassword;
  const navigate = useNavigate();
  const { email, code } = useParams();
  const onSubmit = async () => {
    const userData = {
      email: email,
      code: code,
      password,
      confirmPassword,
    };

    try {
      const response = await fetch(`${ENV.ENDPOINT}/auth/resetPassword`, {
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        const data = await response.json();
        toast.error(t(data.message));
        return;
      }
      toast.success(t("toastPasswordChanged"));
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View style={styles(width, height).view}>
      <img src={IconNewPassword} alt="new-password" />
      <StyledText
        testID="newPasswordTitle"
        superBig
        size={theme.sizes.xxl}
        align="center"
        color={theme.colors.primary}
      >
        {t("newPasswordTitle")}
      </StyledText>
      <View style={styles(width, height).form}>
        <InputPassword
          setPassword={setPassword}
          placeholder={t("newPasswordPlaceholder")}
        />
        <InputPassword
          setPassword={setConfirmPassword}
          placeholder={t("newPasswordPlaceholderConfirm")}
        />
      </View>
      <StyledButton
        testID="newPasswordButton"
        primary
        text={t("resetPasswordTitle")}
        disabled={isDisabled}
        onPress={() => onSubmit()}
      />
    </View>
  );
};

const styles = (width, height) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.colors.primary,
    },
    view: {
      width: width,
      height: height,
      backgroundColor: theme.colors.white,
      paddingHorizontal: theme.spacing.m,
      paddingVertical: theme.spacing.m,
      alignItems: "center",
    },
    form: {
      marginTop: theme.spacing.l,
      marginBottom: theme.spacing.s,
    },
    icon: {
      marginTop: theme.spacing.m,
      marginBottom: theme.spacing.xxs,
      alignSelf: "center",
    },
  });

export default NewPasswordPage;
