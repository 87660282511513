import normonLogo from "./assets/Logo_normon.svg";

export const partners = [
  {
    slug: "normon",
    id: "747b041a-f96e-42d0-804b-20fdfe3e295d",
    logo: normonLogo,
  },
  {
    slug: "ensa",
    id: "8cca948b-61e4-4880-92d2-eb4802134b6e",
    logo: undefined,
  },
];
