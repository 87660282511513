import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native-web";
import StyledText from "../components/StyledText";
import StyledButton from "../components/StyledButton";
import { toast } from "react-toastify";
import { t } from "i18next";
import { theme } from "../styles/theme";
import { ENV } from "../env/env";
import { UserContext } from "../application/UserContext";

const LinkPermissionPage = () => {
  const { width } = useWindowDimensions();
  const { user, setUser } = useContext(UserContext);
  const [permissionPartner, setPermissionPartner] = useState();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await fetch(`${ENV.ENDPOINT}/users/access`, {
          headers: {
            Authorization: `Bearer ${user.allowAccess}`,
          },
        });
        const data = await response.json();
        setPermissionPartner(data);
      } catch (error) {
        console.error(error);
      }
    };
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const grantPermission = async () => {
    try {
      const response = await fetch(`${ENV.ENDPOINT}/users/access`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.allowAccess}`,
        },
        method: "POST",
        body: JSON.stringify({ partnerId: permissionPartner.id }),
      });
      const data = await response.json();
      if (!response.ok) {
        toast.error(t(`${data.message}`));
        return;
      }
      setUser({ ...user, allowAccess: null });
      toast.success(t("toastUserLinked"));
      window.location.href = user.successUrl.includes("http")
        ? `${user.successUrl}?status=granted&userId=${data.userId}`
        : `https://${user.successUrl}?status=granted&userId=${data.userId}`;
    } catch (error) {
      console.error(error);
    }
  };
  const rejectPermission = async () => {
    try {
      window.location.href = user.successUrl.includes("http")
        ? `${user.successUrl}?status=rejected`
        : `https://${user.successUrl}?status=rejected`;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View style={styles(width).container}>
      <View style={styles().topContainer}>
        {permissionPartner && (
          <StyledText subtitle align="center">
            {permissionPartner.name}
          </StyledText>
        )}
        <StyledText
          align="center"
          color={theme.colors.primary}
          mainText
          style={styles().subtitle}
        >
          {t("RequestsPermission")}
        </StyledText>
        <View style={styles(width).buttonsView}>
          <StyledButton
            onPress={grantPermission}
            style={styles(width).button}
            primary
            text={t("Accept")}
          ></StyledButton>
          <StyledButton
            onPress={rejectPermission}
            style={styles(width).button}
            secondary
            color={theme.colors.blueDisabled}
            text={t("Reject")}
          ></StyledButton>
        </View>
      </View>
    </View>
  );
};

const styles = (width) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      paddingTop: theme.spacing.m,
      paddingHorizontal: theme.spacing.m,
      justifyContent: "space-between",
      backgroundColor: theme.colors.white,
    },
    topContainer: {
      alignItems: "center",
    },
    subtitle: {
      marginTop: theme.spacing.xs,
      marginBottom: theme.spacing.s,
    },
    buttonsView: {
      width: width * 0.8,
      flexDirection: "row",
      justifyContent: "space-around",
    },
    button: { marginTop: theme.spacing.s, maxWidth: 200 },
  });

export default LinkPermissionPage;
