import CollectCupIcon from "../assets/cup-collect.svg";
import CollectBowlIcon from "../assets/bowl-blue-collect.svg";
import CollectGreenBowlIcon from "../assets/bowl-green-collect.svg";
import CollectAreasMIcon from "../assets/lend-areas-M.svg";
import CollectAreasLIcon from "../assets/lend-areas-L.svg";

const productTypesIds = {
  Cup: "2af67e9d-2dfb-4bbd-b484-e16836499598",
  CupMKeepin: "b8513e88-3ae8-4f8a-92ca-8629b0fb4390",
  CupLKeepin: "ccbe491c-845b-4e24-87ed-728ca2256f3d",
  Bowl750: "6800ebd2-dff4-4d1e-a6c1-0c601dfe6df0",
  Bowl1250: "11c765d2-5f25-44d9-80b7-252e00d3e554",
  Bowl1250Green: "a6207553-344e-4181-b8e5-046e0e31be50",
  Bowl750Green: "5b933872-7b6e-442f-9784-6cd742dbb4f4",
  Bowl500Green: "d69647e5-ae72-4ded-b0f8-34988c60a184",
};

export const getContainerInfo = (productTypeId) => {
  switch (productTypeId) {
    case productTypesIds.CupMKeepin:
      return { icon: CollectCupIcon, name: "Cup" };

    case productTypesIds.CupLKeepin:
      return { icon: CollectCupIcon, name: "Cup" };

    case productTypesIds.Cup:
      return { icon: CollectCupIcon, name: "Cup" };

    case productTypesIds.Bowl1250:
      return { icon: CollectBowlIcon, name: "Bowl L" };

    case productTypesIds.Bowl750:
      return { icon: CollectBowlIcon, name: "Bowl M" };

    case productTypesIds.Bowl1250Green:
      return { icon: CollectGreenBowlIcon, name: "Bowl L" };
    case productTypesIds.Bowl750Green:
      return { icon: CollectGreenBowlIcon, name: "Bowl M" };
    case productTypesIds.Bowl500Green:
      return { icon: CollectGreenBowlIcon, name: "Bowl S" };

    case "db778dda-b316-4bc3-9282-671f41278917":
      return { icon: CollectAreasMIcon, name: "Areas M" };
    case "e4b59e3b-bfef-4963-8369-563db8cb9d44":
      return { icon: CollectAreasLIcon, name: "Areas L" };
    default:
      return { icon: CollectGreenBowlIcon, name: "Bowl M" };
  }
};
