import React from "react";
import { StyleSheet, View } from "react-native";
import { theme } from "../styles/theme";

const Separator = ({ isOption, color, margin, width }) => {
  return (
    <View style={styles({ color, margin, width }).container}>
      {isOption && (
        <View style={styles({ color }).wrapper}>
          <View style={styles({ color }).circle} />
        </View>
      )}
    </View>
  );
};

const styles = (props) =>
  StyleSheet.create({
    container: {
      width: props.width ? props.width : "100%",
      justifyContent: "center",
      alignItems: "center",
      borderTopWidth: 1,
      opacity: 0.4,
      borderColor: props.color ? props.color : theme.colors.actionableGrey,
      marginVertical: props.margin,
    },
    wrapper: {
      position: "absolute",
      width: 27,
      height: 10,
      backgroundColor: theme.colors.white,
      alignItems: "center",
      justifyContent: "center",
    },
    circle: {
      borderWidth: 1,
      borderRadius: 50,
      borderColor: props.color ? props.color : theme.colors.actionableGrey,
      backgroundColor: "transparent",
      width: theme.sizes.xxs,
      height: theme.sizes.xxs,
    },
  });

export default Separator;
