import React from "react";
import { StyleSheet, Text } from "react-native";
import fonts from "../styles/fonts";

const StyledText = ({
  children,
  brand,
  superBig,
  subtitle,
  mainText,
  mainTextLink,
  screenTitleRegular,
  screenTitleBold,
  tabLabel,
  tabLabelActive,
  align,
  color,
  size,
  style,
  ...props
}) => {
  const textStyles = [
    styles.text,
    brand && styles.brand,
    superBig && styles.superBig,
    subtitle && styles.subtitle,
    mainText && styles.mainText,
    mainTextLink && styles.mainTextLink,
    screenTitleRegular && styles.screenTitleRegular,
    screenTitleBold && styles.screenTitleBold,
    tabLabel && styles.tabLabel,
    tabLabelActive && styles.tabLabelActive,
    color && { color: color },
    align && { textAlign: align },
    size && { fontSize: size },
    style,
  ];
  return (
    <Text {...props} style={textStyles}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "Raleway",
  },
  brand: fonts.brand,
  superBig: fonts.superBig,
  subtitle: fonts.subtitle,
  mainText: fonts.mainText,
  mainTextLink: fonts.mainTextLink,
  screenTitleRegular: fonts.screenTitleRegular,
  screenTitleBold: fonts.screenTitleBold,
  tabLabel: fonts.tabLabel,
  tabLabelActive: fonts.tabLabelActive,
});

export default StyledText;
