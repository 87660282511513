import React from "react";
import { Pressable, StyleSheet, useWindowDimensions, View } from "react-native";
import StyledText from "../components/StyledText";
import { t } from "i18next";
import IconVerificationCode from "../assets/icon-verification-code.svg";
import { useNavigate, useParams } from "react-router-dom";
import { theme } from "../styles/theme";
import VerificationInput from "react-verification-input";
import { toast } from "react-toastify";
import { ENV } from "../env/env";
const VerificationCodePage = () => {
  const { width, height } = useWindowDimensions();
  const { email } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (code) => {
    const credentials = {
      email: email,
      code: code,
    };
    try {
      const response = await fetch(
        `${ENV.ENDPOINT}/auth/verifyResetPasswordCode`,
        {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify(credentials),
        }
      );
      const data = await response.json();
      if (!data.success) {
        toast.error(t(data.message));
        return;
      }
      navigate(`/newPassword/${email}/${data.data}`);
    } catch (error) {
      console.error(error);
    }
  };

  const sendCode = async () => {
    try {
      const response = await fetch(
        `${ENV.ENDPOINT}/auth/sendResetPasswordCode`,
        {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({ email }),
        }
      );
      if (!response.ok) {
        toast.error(t("defaultError"));
        return;
      }
      toast.success(t("toastSentEmail"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View style={styles(width, height).view}>
      <img
        src={IconVerificationCode}
        style={styles(width, height).icon}
        alt="verification-code-icon"
      />
      <StyledText
        testID="verificationCodeTitle"
        superBig
        size={30}
        align="center"
        color={theme.colors.primary}
      >
        {t("verificationCodeTitle")}
      </StyledText>
      <StyledText
        style={{ marginVertical: theme.spacing.xs }}
        mainText
        size={theme.sizes.m}
        align="center"
      >
        {t("verificationCodesubtitle")}
        <StyledText screenTitleBold size={theme.sizes.l}>
          {email}
        </StyledText>
      </StyledText>
      <VerificationInput
        validChars="0-9"
        placeholder=""
        onComplete={onSubmit}
      />
      <StyledText mainText align="center" style={styles(width, height).link}>
        {t("verificationCodeReSend")}
        <Pressable testID="resendLink" onPress={sendCode}>
          <StyledText mainTextLink>
            {t("verificationCodeReSendLink")}
          </StyledText>
        </Pressable>
      </StyledText>
    </View>
  );
};

const styles = (width, height) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.colors.primary,
    },
    view: {
      alignItems: "center",
      width: width,
      height: height,
      paddingHorizontal: theme.spacing.m,
      paddingVertical: theme.spacing.m,
      backgroundColor: theme.colors.white,
    },
    icon: {
      marginTop: theme.spacing.m,
      marginBottom: theme.spacing.xxs,
      alignSelf: "center",
    },
    link: {
      marginTop: theme.spacing.l,
    },
  });

export default VerificationCodePage;
