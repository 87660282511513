import React, { useState } from "react";
import { Pressable, StyleSheet, TextInput, View } from "react-native";
import Eye from "../assets/eye.svg";
import EyeOff from "../assets/eyeOff.svg";

import { t } from "i18next";
import { theme } from "../styles/theme";
import { useWindowDimensions } from "react-native-web";

const InputPassword = ({ setPassword, placeholder, ...props }) => {
  const [hidePassword, sethidePassword] = useState(true);
  const { width } = useWindowDimensions();
  const handlePasswordVisibility = () => {
    if (hidePassword === false) {
      sethidePassword(true);
      return;
    }
    sethidePassword(false);
  };

  return (
    <View style={[styles(width).form]}>
      <TextInput
        testID="passwordInput"
        {...props}
        style={styles(width).input}
        secureTextEntry={hidePassword}
        onChangeText={(event) => setPassword(event.valueOf())}
        autoComplete="off"
        textContentType="password"
        autoCorrect={false}
        autoCapitalize="none"
        enablesReturnKeyAutomatically={true}
        multiline={false}
        placeholder={placeholder ?? t("PlaceholderPassword")}
        placeholderTextColor={"darkgray"}
      />
      <Pressable style={styles(width).eye} onPress={handlePasswordVisibility}>
        {hidePassword ? (
          <img src={EyeOff} alt="hidePassword" />
        ) : (
          <img src={Eye} alt="showPassword" />
        )}
      </Pressable>
    </View>
  );
};

const styles = (width) =>
  StyleSheet.create({
    form: {
      width: width - 40,
      maxWidth: 500,
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.primaryDisabled,
      marginBottom: theme.spacing.m,
      fontSize: 50,
    },
    eye: {
      marginLeft: "auto",
      marginRight: 20,
    },
    input: {
      flex: 1,
      fontSize: theme.sizes.l,
      color: theme.colors.black,
      padding: theme.spacing.s,
    },
    label: {
      fontSize: theme.sizes.m,
      color: theme.colors.black,
      marginLeft: theme.spacing.s,
      marginBottom: -7,
    },
  });

export default InputPassword;
