import {
  CheckBox,
  Linking,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native-web";
import { theme } from "../styles/theme";
import StyledText from "../components/StyledText";
import { useContext, useEffect, useState } from "react";
import InputEmail from "../components/InputEmail";
import InputText from "../components/InputText";
import InputPassword from "../components/InputPassword";
import StyledButton from "../components/StyledButton";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../application/UserContext";
import { toast } from "react-toastify";
import { ENV } from "../env/env";
import i18next, { t } from "i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import queryString from "query-string";
import ReactPasswordChecklist from "react-password-checklist";
const LoginPage = () => {
  const [loginTab, setLoginTab] = useState(true);
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newsletterCheck, setNewsletterCheck] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [text, setText] = useState("");
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const validateEmail = (text) => {
    const reg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!reg.test(text)) {
      setEmailIsValid(false);
      return true;
    }
    if (text.includes("@gruposantander")) {
      setEmailIsValid(false);
      return true;
    }
  };

  useEffect(() => {
    const loadParams = async () => {
      const name = await AsyncStorage.getItem("name");
      const email = await AsyncStorage.getItem("email");
      const tab = await AsyncStorage.getItem("tab");
      email !== "undefined" && setEmail(email);
      name !== "undefined" && setText(name);
      tab !== "undefined" && tab === "register"
        ? setLoginTab(false)
        : setLoginTab(true);
    };
    loadParams();
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      const token = await AsyncStorage.getItem("accessToken");
      if (token && token !== "undefined") {
        navigate("/userCode", { replace: true });
      }
    };
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.allowAccess) {
      navigate("/linkPermission", { replace: true });
    }
    if (user?.accessToken) {
      navigate("/userCode", { replace: true });
    }
  }, [navigate, user?.accessToken, user?.allowAccess]);

  const onLogin = async (body) => {
    if (validateEmail(body.email)) {
      return;
    }
    setEmailIsValid(true);
    try {
      const response = await fetch(`${ENV.ENDPOINT}/auth/login`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(t(data.code));
        return;
      }
      await AsyncStorage.setItem("accessToken", data.data.accessToken);
      const currentUserResponse = await fetch(`${ENV.ENDPOINT}/users/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
        },
      });
      const currentUser = await currentUserResponse.json();
      if (!currentUser.success) {
        toast.error(t(currentUser.code));
        return;
      }
      const { successUrl, allowAccess } = queryString.parse(
        window.location.search
      );
      setUser({ ...currentUser.data, accessToken: data.data.accessToken });
      successUrl &&
        allowAccess &&
        setUser({
          ...currentUser.data,
          successUrl: successUrl,
          allowAccess: allowAccess,
        });
    } catch (error) {
      console.error(error);
    }
  };
  const onRegister = async (body) => {
    if (validateEmail(body.email)) {
      return;
    }
    setEmailIsValid(true);
    try {
      const response = await fetch(`${ENV.ENDPOINT}/auth/register`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(t(data.code));
        return;
      }
      await AsyncStorage.setItem("accessToken", data.data.accessToken);
      const currentUserResponse = await fetch(`${ENV.ENDPOINT}/users/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
        },
      });
      const currentUser = await currentUserResponse.json();
      if (!currentUser.success) {
        toast.error(t(currentUser.code));
        return;
      }
      setUser({ ...currentUser.data, accessToken: data.data.accessToken });
    } catch (error) {
      console.error(error);
    }
  };

  const formIncomplete =
    email === "" ||
    text === "" ||
    !validPassword ||
    termsAndConditions === false;

  return (
    <>
      <View
        style={{
          backgroundColor: theme.colors.white,
          width: width,
        }}
      >
        <View style={styles(width).tabs}>
          <Pressable
            testID="loginTab"
            onPress={() => {
              setLoginTab(true);
            }}
            style={styles(width).tab}
          >
            <StyledText
              style={!loginTab && styles().opacity}
              subtitle
              color={theme.colors.primary}
              mainTextLink={loginTab}
              size={theme.sizes.l}
            >
              {t("LogIn")}
            </StyledText>
          </Pressable>
          <Pressable
            testID="registerTab"
            onPress={() => {
              setLoginTab(false);
            }}
            style={styles(width).tab}
          >
            <StyledText
              style={loginTab && styles().opacity}
              subtitle
              color={theme.colors.primary}
              mainTextLink={!loginTab}
              size={theme.sizes.l}
            >
              {t("Register")}
            </StyledText>
          </Pressable>
        </View>
      </View>
      {loginTab ? (
        <View style={styles(width).formView}>
          <InputEmail
            defaultValue={email}
            setEmail={setEmail}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
          />
          <View style={styles(width).formViewPassword}>
            <InputPassword setPassword={setPassword} />
          </View>
          <StyledButton
            testID="loginButton"
            style={{ marginVertical: theme.spacing.s }}
            text={t("LogIn")}
            disabled={email === "" || password === ""}
            onPress={() => onLogin({ email, password })}
            primary
          />
          <Link to={"forgotPassword"}>
            <StyledText style={{ marginBottom: theme.spacing.m }} mainTextLink>
              {t("ForgottenPassword")}
            </StyledText>
          </Link>
        </View>
      ) : (
        <View style={styles(width).formView}>
          <InputEmail
            defaultValue={email}
            setEmailIsValid={setEmailIsValid}
            setEmail={setEmail}
            emailIsValid={emailIsValid}
          />
          <InputText defaultValue={text} setText={setText} />
          <View style={styles(width).formViewPassword}>
            <InputPassword setPassword={setPassword} />
          </View>
          <View style={styles(width).formViewPassword}>
            <InputPassword
              setPassword={setConfirmPassword}
              placeholder={t("placeholderRepeatPassword")}
            />
          </View>
          <ReactPasswordChecklist
            style={{ fontFamily: "Raleway", marginBottom: theme.spacing.m }}
            rules={[
              "minLength",
              "specialChar",
              "number",
              "match",
              "notEmpty",
              "capitalAndLowercase",
            ]}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={(isValid) => {
              setValidPassword(isValid);
            }}
            messages={{
              minLength: t("passwordRuleCharacters"),
              specialChar: t("passwordRuleSpecial"),
              number: t("passwordRuleNumber"),
              match: t("passwordRuleMatch"),
              notEmpty: t("passwordNotEmpty"),
              capitalAndLowercase: t("passwordRuleLowercase"),
            }}
          />
          <View>
            <View style={styles().newsLetterView}>
              <CheckBox
                testID="newsletterCheck"
                boxType="square"
                tintColor={
                  newsletterCheck
                    ? theme.colors.primary
                    : theme.colors.primaryDisabled
                }
                value={newsletterCheck}
                onValueChange={() => setNewsletterCheck(!newsletterCheck)}
              />
              <StyledText style={styles().checkText} mainText>
                {t("Communications")}
              </StyledText>
            </View>
            <View style={styles().newsLetterView}>
              <CheckBox
                testID="termsCheck"
                tintColor={
                  newsletterCheck
                    ? theme.colors.primary
                    : theme.colors.primaryDisabled
                }
                value={termsAndConditions}
                onValueChange={() => setTermsAndConditions(!termsAndConditions)}
              />
              <Pressable
                onPress={() =>
                  Linking.openURL(
                    "https://youbumerang.com/terminos-y-condiciones-uso-app"
                  )
                }
              >
                <StyledText style={styles().checkText} mainText>
                  {t("AcceptNewsLetters")}
                  <StyledText mainTextLink size={theme.sizes.s}>
                    {t("AcceptNewsLettersLink")}
                  </StyledText>
                </StyledText>
              </Pressable>
            </View>
          </View>
          <StyledButton
            testID="registerButton"
            style={{ marginVertical: theme.spacing.s }}
            text={t("ContinueWithPaymentMethod")}
            onPress={() =>
              onRegister({
                email,
                password,
                name: text,
                platform: "web",
                language: i18next.language.slice(0, 2),
              })
            }
            disabled={formIncomplete}
            primary
          />
        </View>
      )}
    </>
  );
};

const styles = (width) =>
  StyleSheet.create({
    opacity: { opacity: 0.7 },
    checkText: { marginLeft: theme.spacing.xs },
    tabs: {
      height: 70,
      shadowColor: "black",
      shadowOpacity: 0.26,
      shadowOffset: { width: 0, height: 2 },
      elevation: 3,
      marginBottom: 2,
      maxWidth: 800,
      width: width,
      alignSelf: "center",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      backgroundColor: theme.colors.white,
    },
    tab: {
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    formView: {
      flex: 1,
      width: width,
      alignItems: "center",
      padding: theme.spacing.s,
      backgroundColor: theme.colors.white,
    },
    formViewPassword: {
      flexDirection: "row",
      alignItems: "center",
    },
    newsLetterView: {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
      marginBottom: theme.spacing.xs,
      paddingRight: theme.spacing.m,
    },
  });

export default LoginPage;
