import React, { useContext } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native-web";
import { theme } from "../styles/theme";
import StyledButton from "../components/StyledButton";
import queryString from "query-string";
import { t } from "i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UserContext } from "../application/UserContext";
const PwaTestPage = () => {
  const { width } = useWindowDimensions();
  const query = queryString.parse(window.location.search);
  const { setUser } = useContext(UserContext);

  return (
    <View style={styles(width).container}>
      <View style={styles().topContainer}>
        <StyledButton
          onPress={() => {
            window.parent?.postMessage(
              JSON.stringify({ event: "userId", userId: "B-3E4653" }),
              "*"
            );
            window.AndroidInterface?.receiveMessage(
              JSON.stringify({ event: "userId", userId: "B-3E4653" })
            );

            window.ReactNativeWebView?.postMessage(
              JSON.stringify({ event: "userId", userId: "B-3E4653" }),
              "*"
            );
            window.webkit?.messageHandlers?.bumerangListener?.postMessage(
              JSON.stringify({ event: "userId", userId: "B-3E4653" }),
              "*"
            );
          }}
          style={{ marginTop: theme.spacing.s }}
          primary
          text={"User ID"}
        ></StyledButton>
        <StyledButton
          onPress={() => {
            if (query.redirection) {
              window.location.href = query.redirection.includes("http")
                ? `${query.redirection}?userId=B-3E4653`
                : `https://${query.redirection}?userId=B-3E4653`;
            }
            window.parent?.postMessage(
              JSON.stringify({ event: "exit", userId: "B-3E4653" }),
              "*"
            );
            window.AndroidInterface?.receiveMessage(
              JSON.stringify({ event: "exit", userId: "B-3E4653" })
            );

            window.ReactNativeWebView?.postMessage(
              JSON.stringify({ event: "exit", userId: "B-3E4653" }),
              "*"
            );
            window.webkit?.messageHandlers?.bumerangListener?.postMessage(
              JSON.stringify({ event: "exit", userId: "B-3E4653" }),
              "*"
            );
          }}
          style={{ marginTop: theme.spacing.s }}
          primary
          text={t("SyncAndClose")}
        ></StyledButton>
        <StyledButton
          primary
          style={{ marginTop: theme.spacing.s }}
          text={t("LogOut")}
          onPress={() => {
            setUser({});
            AsyncStorage.multiRemove(["accessToken", "email", "name", "tab"]);
            if (query.redirection) {
              window.location.href = query.redirection.includes("http")
                ? `${query.redirection}`
                : `https://${query.redirection}`;
            }
            window.parent?.postMessage(
              JSON.stringify({ event: "logout" }),
              "*"
            );
            window.AndroidInterface?.receiveMessage(
              JSON.stringify({ event: "logout" })
            );

            window.ReactNativeWebView?.postMessage(
              JSON.stringify({ event: "logout" }),
              "*"
            );
            window.webkit?.messageHandlers?.bumerangListener?.postMessage(
              JSON.stringify({ event: "logout" }),
              "*"
            );
          }}
        ></StyledButton>
      </View>
    </View>
  );
};
const styles = (width) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      paddingTop: theme.spacing.m,
      paddingHorizontal: theme.spacing.m,
      justifyContent: "space-between",
      backgroundColor: theme.colors.white,
    },
    topContainer: {
      alignItems: "center",
    },
    subtitle: {
      marginTop: theme.spacing.xs,
      marginBottom: theme.spacing.s,
    },
    shadowBox: {
      marginVertical: theme.spacing.m,
    },
    qrViewBox: {
      height: 220,
      backgroundColor: theme.colors.white,
      width: width * 0.67,
      paddingBottom: theme.spacing.m,
      borderRadius: 20,
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    qrBoxHeader: {
      width: "100%",
      flexWrap: "wrap",
      paddingHorizontal: theme.spacing.xs,
      alignContent: "center",
      alignItems: "center",
    },
    nameTitleStyle: {
      maxWidth: "70%",
    },
    chatBubbleIcon: {
      position: "absolute",
      left: 15,
    },
    qrCodeBox: {
      backgroundColor: theme.colors.white,
      width: width * 0.67,
      borderRadius: 5,
      height: 45,
      alignItems: "center",
      justifyContent: "space-evenly",
      flexDirection: "row",
      marginBottom: theme.spacing.s,
    },
    icon: {
      marginRight: theme.spacing.xs,
    },
    copyTextBox: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginBottom: theme.spacing.s,
    },
  });

export default PwaTestPage;
