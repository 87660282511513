export const theme = {
  colors: {
    actionableGrey: "#5f6368",
    primary: "#023E43",
    primaryDisabled: "#4C767A",
    backgroundGreen: "#F8FEFB",
    detailsGreen: "#61A27B",
    detailsYellow: "#FFF243",
    detailsSalmon: "#FF9769",
    tagCream: "#FCD2C0",
    tagCoral: "#ffb7be",
    tagOrange: "#FEB595",
    tagPurple: "#B9B5E8",
    success: "#59caa8",
    error: "#ff7777",
    white: "#ffffff",
    black: "#1d1c1a",
    backgroundBlue: "#F4FAFF",
    blueDisabled: "#748D9F",
    separator: "#A1B3B4",
    backgroundAreas: "#87BCB2",
  },
  sizes: {
    xxs: 10,
    xs: 12,
    s: 14,
    m: 16,
    l: 18,
    xl: 20,
    xxl: 30,
    xxxl: 40,
  },
  spacing: {
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 40,
    xxl: 48,
    xxxl: 56,
    xxxxl: 64,
    xxxxxl: 128,
    xxxxxxl: 138,
  },
};
